@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full bg-gray-50 dark:bg-gray-900;
}

body {
  @apply h-full overflow-hidden;
}

/* https://github.com/josdejong/jsoneditor/blob/develop/src/js/ace/theme-jsoneditor.js */

.ace-jsoneditor .ace_gutter {
  @apply bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100;
}

.ace-jsoneditor.ace_editor,
.ace-jsoneditor .ace_scroller {
  @apply bg-white dark:bg-gray-900;
}

.ace-jsoneditor .ace_print-margin {
  @apply bg-gray-50 dark:bg-gray-800;
}

.ace-jsoneditor .ace_text-layer {
  @apply text-gray-900 dark:text-white;
}

.ace-jsoneditor .ace_variable {
  @apply text-gray-800 dark:text-gray-100;
}

.ace-jsoneditor .ace_cursor {
  @apply border-gray-900 dark:border-white;
}

.ace-jsoneditor .ace_overwrite-cursors .ace_cursor {
  @apply border-gray-900 dark:border-white;
}

.ace-jsoneditor .ace_marker-layer .ace_selection {
  @apply bg-gray-50 dark:bg-gray-800;
}

.ace-jsoneditor .ace_marker-layer .ace_step {
  @apply bg-red-300 dark:bg-red-300;
}

.ace-jsoneditor .ace_marker-layer .ace_bracket {
  @apply border-gray-100 dark:border-gray-700;
}

.ace-jsoneditor .ace_marker-layer .ace_active-line {
  @apply bg-gray-100 dark:bg-gray-800;
}

.ace-jsoneditor .ace_gutter-active-line {
  @apply bg-gray-200 dark:bg-gray-700;
}

.ace-jsoneditor .ace_marker-layer .ace_selected-word {
  @apply border-gray-100 dark:border-gray-700;
}

.ace-jsoneditor .ace_invisible {
  @apply text-gray-100 dark:border-gray-700;
}

.ace-jsoneditor .ace_keyword,
.ace-jsoneditor .ace_meta,
.ace-jsoneditor .ace_support.ace_constant.ace_property-value {
  color: #af956f;
}

.ace-jsoneditor .ace_keyword.ace_operator {
  @apply text-gray-800 dark:text-gray-200;
}

.ace-jsoneditor .ace_keyword.ace_other.ace_unit {
  color: #96dc5f;
}
.ace-jsoneditor .ace_constant.ace_language {
  color: darkorange;
}
.ace-jsoneditor .ace_constant.ace_numeric {
  color: red;
}
.ace-jsoneditor .ace_constant.ace_character.ace_entity {
  color: #bf78cc;
}
.ace-jsoneditor .ace_invalid {
  color: #ffffff;
  background-color: #ff002a;
}
.ace-jsoneditor .ace_fold {
  background-color: #af956f;
  @apply border-gray-800 dark:border-gray-200;
}

.ace-jsoneditor .ace_storage,
.ace-jsoneditor .ace_support.ace_class,
.ace-jsoneditor .ace_support.ace_function,
.ace-jsoneditor .ace_support.ace_other,
.ace-jsoneditor .ace_support.ace_type {
  color: #c52727;
}

.ace-jsoneditor .ace_string {
  @apply text-green-600 dark:text-green-400;
}
.ace-jsoneditor .ace_comment {
  color: #bcc8ba;
}
.ace-jsoneditor .ace_entity.ace_name.ace_tag,
.ace-jsoneditor .ace_entity.ace_other.ace_attribute-name {
  @apply text-gray-800 dark:text-gray-200;
}

@layer utilities {
  /* Scroll thumb styles */
  .scrollbar::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* .scrollbar::-webkit-scrollbar-thumb {
    background: #27272e;
    border-radius: 1rem;
  }
  .scrollbar::-webkit-scrollbar-thumb:active {
    background-color: #131314;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #3b3b46;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #131314;
    border-radius: 4px;
  }
  .scrollbar::-webkit-scrollbar-track:hover,
  .scrollbar::-webkit-scrollbar-track:active {
    background: #131314;
  } */
}

.panel-container {
  @apply flex;
}

.resizer {
  width: 4px;
  background: darkGray;
  position: relative;
  cursor: col-resize;
  flex-shrink: 0;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.resizer::after,
.resizer::before {
  content: "";
  border-left: 1px solid #333;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  right: 0;
  display: inline-block;
  height: 20px;
  margin: 0 2px;
}

.resizer::before {
  left: 0;
}
