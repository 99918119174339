/*

    Name:       3024 day
    Author:     Jan T. Sott (http://github.com/idleberg)

    CodeMirror template by Jan T. Sott (https://github.com/idleberg/base16-codemirror)
    Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16)

*/

.cm-s-3024-day.CodeMirror {
  background: #f7f7f7;
  color: #3a3432;
}
.cm-s-3024-day div.CodeMirror-selected {
  background: #d6d5d4;
}

.cm-s-3024-day .CodeMirror-line::selection,
.cm-s-3024-day .CodeMirror-line > span::selection,
.cm-s-3024-day .CodeMirror-line > span > span::selection {
  background: #d6d5d4;
}
.cm-s-3024-day .CodeMirror-line::-moz-selection,
.cm-s-3024-day .CodeMirror-line > span::-moz-selection,
.cm-s-3024-day .CodeMirror-line > span > span::selection {
  background: #d9d9d9;
}

.cm-s-3024-day .CodeMirror-gutters {
  background: #f7f7f7;
  border-right: 0px;
}
.cm-s-3024-day .CodeMirror-guttermarker {
  color: #db2d20;
}
.cm-s-3024-day .CodeMirror-guttermarker-subtle {
  color: #807d7c;
}
.cm-s-3024-day .CodeMirror-linenumber {
  color: #807d7c;
}

.cm-s-3024-day .CodeMirror-cursor {
  border-left: 1px solid #5c5855;
}

.cm-s-3024-day span.cm-comment {
  color: #cdab53;
}
.cm-s-3024-day span.cm-atom {
  color: #a16a94;
}
.cm-s-3024-day span.cm-number {
  color: #a16a94;
}

.cm-s-3024-day span.cm-property,
.cm-s-3024-day span.cm-attribute {
  color: #01a252;
}
.cm-s-3024-day span.cm-keyword {
  color: #db2d20;
}
.cm-s-3024-day span.cm-string {
  color: #fded02;
}

.cm-s-3024-day span.cm-variable {
  color: #01a252;
}
.cm-s-3024-day span.cm-variable-2 {
  color: #01a0e4;
}
.cm-s-3024-day span.cm-def {
  color: #e8bbd0;
}
.cm-s-3024-day span.cm-bracket {
  color: #3a3432;
}
.cm-s-3024-day span.cm-tag {
  color: #db2d20;
}
.cm-s-3024-day span.cm-link {
  color: #a16a94;
}
.cm-s-3024-day span.cm-error {
  background: #db2d20;
  color: #5c5855;
}

.cm-s-3024-day .CodeMirror-activeline-background {
  background: #e8f2ff;
}
.cm-s-3024-day .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #a16a94 !important;
}
